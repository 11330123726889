import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'ULTRASANO dr. Saghir', // e.g: 'Name | Developer'
  lang: 'hr', // e.g: en, es, fr, jp
  description: 'Privatna ambulanta za ultrazvuk', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const servicesData = [
  {
    id: nanoid(),
    img: 'ultrasound-2.png',
    title: 'ultrazvuk',
    values: [
      'srca',
      'abdomena',
      'urotrakta (bubrega i mokraćnog mjehura)',
      'dojki',
      'vrata',
      'štitne žlijezde i parotidne žlijezde',
      `lokomotornog sustava
      (ramena, koljena, mišića)`,
      'skrotuma i testisa',
      'prostate i mokraćnog mjehura',
      'dječjih kukova',
      'limfnih čvorova',
      'aksila i ingvinuma',
    ],
  },
  {
    id: nanoid(),
    img: 'ultrasound-scan.jpg',
    title: 'cd-collor doppler',
    values: [
      'karotidnih (vratnih) arterija i VB sliva',
      'TCD traskranijalni doppler',
      'arterija nogu',
      'arterija ruku',
      'vena nogu',
      'vena ruku',
      'abdomenalne aorte',
      'bubrega i renalnih arterija',
      'testisa',
      'ostalih organa',
    ],
  },
];

export const pricesData = [
  {
    id: 'ultrasound',
    title: 'Ultrazvuk',
    values: [
      {
        name: 'Srca',
        price: 60,
        description: ` Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled uobičajeno traje od 15 minuta do sat vremena.
UZV srca daje informacije o srčanom mišiću, srčanim ovojnicama, prirođenim anomalijama, stanju uzlazne aorte, funkciji zalistaka, protoku u srčanim komorama. `,
      },
      {
        name: 'Abdomena',
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled uobičajeno traje od 15 minuta.
UZV gornjeg abdomena uključuje, pregled jetre. ž. mjehura, ž. vodova, gušterače i slezene.
Promatra se položaj, oblik i veličina, eventualne promjene, pojave tumora, ciste ili drugih bolesti. 
Na pregled treba doći natašte ili najmanje 5 sati od posljednjeg zadnjeg obroka.`,
        price: [
          {
            name: 'Gornji abdomen',
            value: 40,
          },

          {
            name: 'Cijeli abdomen',
            value: 53,
          },
        ],
      },
      {
        name: 'Urotrakta (bubrega i mokraćnog mjehura)',
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled uobičajeno traje od 15 minuta.
UZV urotrakta uključuje pregled oba bubrega i m. mjehura.
Promatra se položaj, oblik i veličina , eventualne promjene, pojave tumora, ciste ili drugih bolesti. 
Na pregled treba doći s punim mjehurom.`,
      },
      {
        name: 'Dojki',
        price: 46,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
Za vrijeme UZV pregleda koji je bezbolan, pacijentica leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled  dojki uobičajeno traje od 20 do 30 minuta, u njemu je uključen pregled limfnih čvorova pazušne jame.
UZV pregled dojki ako ne postoji hitnosti, poželjno je obaviti između 8 i 12 dana ciklusa time da je prvi dan ciklusa ujedno prvi dan krvarenja.`,
      },
      {
        name: 'Vrata',
        price: 46,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled  vrata uobičajeno traje 20 minuta, u njemu je uključen pregled štitne i submandibularne žlijezde, limfnih čvorova, okolnih mišića i informativni pregled krvnih žila vrata. `,
      },
      {
        name: 'Štitne žlijezde i parotidne žlijezde',
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled uobičajeno traje 15 minuta, u njemu je uključen pregled štitne i parotidne  žlijezde, mjeri se veličina i promatra građa tkiva.`,
      },
      {
        name: `Lokomotornog sustava
      (ramena, koljena, mišića i ostalih zglobova)`,
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
Za vrijeme UZV pregleda koji je bezbolan, pacijent pretežno leži ponekad sjedi ili stoji .
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled  koštano mišićnog aparata uobičajeno traje 20 minuta uključuje ispitivanje pokretljivosti zgloba, stanje mišića i tetiva u smislu puknuća ili pojave hematoma, izljeva. `,
      },
      {
        name: 'Skrotuma i testisa',
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled  skrotuma uobičajeno traje 20 minuta, u njemu je uključen pregled testisa, ovojnica u kojemu se nalaze testisi, informativni pregled krvnih žila.
`,
      },
      {
        name: 'Prostate i mokraćnog mjehura',
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled  m. mjehura i prostate uobičajeno traje 15 minuta, u njemu je uključen pregled m. mjehura, raste li u njemu tumor, stanje stjenke, mjerenje veličine prostate i promatranje tkiva . 
Na pregled treba doci s punim m. mjehurom.
`,
      },
      {
        name: 'Dječjih kukova',
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled  dječjih kukova  traje 15 minuta, pokušava se otkriti iščašenje kuka mjerenjem pripadajućih kutova, promatranje građe i zrelosti zgloba. 
`,
      },
      {
        name: 'Limfnih čvorova (Aksila i Ingvinuma)',
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV pregled  limfnih čvorova uobičajeno traje 20 minuta. 
`,
        // },
        // {
        //   name: ' Aksila i ingvinuma (s linmfnim čvorovima)',
        //   price: 40,
        //   description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
        //   UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
        //   UZV pregled je u potpunosti bezbolan, a pacijent leži.
        //   Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
        //   UZV pregled  limfnih čvorova uobičajeno traje 20 minuta.
        //   `,
      },
    ],
  },
  {
    id: 'doppler',
    title: 'CD-color doppler',
    values: [
      {
        name: 'Karotidnih (vratnih) arterija i VB sliva',
        price: 53,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV i CD (color Doppler), karotidnih arterija i vertebrobazilarnog sliva, uobičajeno traje 30 minuta. 
Dopplerskom tehnikom promatra i mjeri se protok krvi u krvnim žilama vrata te nalaze li se promjene u stijenkama (tvrdi ili meki plakovi) koje uzrokuju suženje krvnih žila.`,
      },
      {
        name: 'TCD traskranijalni doppler',
        price: 53,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV i CD (color Doppler), arterija mozga uobičajeno traje 30 minuta. 
Dopplerskom tehnikom promatra i mjeri se protok krvi u krvnim žilama mozga, ima li promjene u stijenkama (tvrdi ili meki plakovi) koje uzrokuju suženje krvnih žila, poremećaj u razvoju.
`,
      },
      {
        name: 'Arterija nogu',
        price: 53,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV i CD (color Doppler), arterija nogu uobičajeno traje 45 minuta. 
Dopplerskom tehnikom promatra i mjeri se protok krvi u krvnim žilama nogu, ima li promjene u stijenkama (tvrdi ili meki plakovi) koje uzrokuju suženje krvnih žila te ima li proširenja krvnih žila (aneurizma).`,
      },
      {
        name: 'Arterija ruku',
        price: 53,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV i CD (color Doppler), arterija ruku uobičajeno traje 45 minuta. 
Dopplerskom tehnikom promatra i mjeri se protok krvi u krvnim žilama nogu, ima li promjene u stijenkama (tvrdi ili meki plakovi) koje uzrokuju suženje krvnih žila te ima li proširenja krvnih žila (aneurizma).
`,
      },
      {
        name: 'Vena nogu',
        price: 53,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
        UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
        UZV pregled je u potpunosti bezbolan, a pacijent leži.
        Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
        UZV i CD (color Doppler), arterija i vena ruku uobičajeno traje 60 minuta. 
        Dopplerskom tehnikom promatra i mjeri se protok krvi u arterija nogu, ima li promjene stijenkama (tvrdi ili meki plakovi) koje uzrokuju suženje krvnih žila te ima li proširenja krvnih žila (aneurizma). U venama se ispituje postoji li tromboza površinskih ili dubokih vena, odnosno oštećenje valvularnog aparata.`,
      },
      {
        name: 'Vena ruku',
        price: 53,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
        UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
        UZV pregled je u potpunosti bezbolan, a pacijent leži.
        Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
        UZV i CD (color Doppler), arterija i vena ruku uobičajeno traje 60 minuta. 
        Dopplerskom tehnikom promatra i mjeri se protok krvi u arterija nogu, ima li promjene u stijenkama (tvrdi ili meki plakovi) koje uzrokuju suženje krvnih žila te ima li proširenja krvnih žila (aneurizma). U venama se ispituje postoji li tromboza površinskih ili dubokih vena, odnosno oštećenje valvularnog aparata.`,
      },
      {
        name: 'Abdomenalne aorte',
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV i CD (color Doppler), aorte  uobičajeno traje 20 minuta. 
Dopplerskom tehnikom promatra i mjeri se protok krvi u aorti, ima li promjene u stijenkama (tvrdi ili meki plakovi) koje uzrokuju suženje krvnih žila te ima li proširenja krvnih žila (aneurizma). 
`,
      },
      {
        name: 'Bubrega i renalnih arterija',
        price: 53,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV i CD (color Doppler), renalnih arterija uobičajeno traje 45 minuta. 
Dopplerskom tehnikom promatra i mjeri se protok krvi u krvnim žilama  bubrega , ima li promjene u stijenkama (tvrdi ili meki plakovi), promjena u protoku u krvnim žilama što posljedično može uzrokovati hipertenziju (visoki tlak).
`,
      },
      {
        name: 'Testisa',
        price: 40,
        description: `Ultrazvuk (UZV) je dijagnostička metoda oslikavanja koja koristi ultrazvučne valove visoke frekvencije za dobivanje slike unutarnjih i površinskih organa, izvodi se u realnom vremenu (živa slika) zbog čega je moguće procijeniti protok krvi kroz krvne žile.
UZV je znanstveno dokazano neškodljiv pregled za ljudski organizam, može se ponavljati koliko treba bez straha od štetnih posljedica poput ionizirajućih zračenje koje se upotrebljavaju prilikom RTG ili CT snimanja.
UZV pregled je u potpunosti bezbolan, a pacijent leži.
Nanosi se ultrazvučni gel  na pregledavanog dio tijela koji služi za bolji dodir UZV sonde s kožom i onemogućuje pojavu zraka između sonde i kože.
UZV i CD (color Doppler), testisa uobičajeno traje 20 minuta. 
Dopplerskom tehnikom promatra i mjeri se protok krvi u krvnim žilama testisa, te postoji li proširenja vena u smislu varikoziteta.`,
      },
    ],
  },
  // {
  //   id: 'extras',
  //   title: 'Dodatno',
  //   values: [
  //     {
  //       name: 'Konzultacije, savjeti i druga mišljenja',
  //       price: 200,
  //       description: ``,
  //     },
  //     {
  //       name: 'Hitne i vikend pretrage: cijena pretrage +',
  //       price: 13,
  //       description: ``,
  //     },
  //   ],
  // },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
